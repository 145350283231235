import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  i,
  r
} from "./chunk.5YGJIHTM.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/jb-lazy-loading-uploads/jb-lazy-loading-uploads.scss?inline
var jb_lazy_loading_uploads_default = i`:host {
  display: block;
  width: 100%;
}

slot {
  display: grid;
  row-gap: 11px;
}`;

// src/components/jb-lazy-loading-uploads/jb-lazy-loading-uploads.ts
var JbLazyLoadingUploads = class extends s {
  firstUpdated() {
    this._uploads = Array.from(this.querySelectorAll("jb-upload"));
    this._showFilledPlusOneEmpty();
  }
  _showFilledPlusOneEmpty() {
    this._uploads.forEach((upload) => {
      upload.style.display = "none";
    });
    this._uploads.filter((upload) => upload.isUploaded).forEach((upload) => {
      upload.style.display = "initial";
    });
    const firstEmptyField = this._uploads.filter((upload) => !upload.isUploaded).shift();
    if (firstEmptyField) {
      firstEmptyField.style.display = "initial";
    }
  }
  render() {
    return x`<section><slot @jb-upload:field-change=${this._showFilledPlusOneEmpty}></slot></section>`;
  }
};
JbLazyLoadingUploads.styles = r(jb_lazy_loading_uploads_default);
JbLazyLoadingUploads = __decorateClass([
  customElement("jb-lazy-loading-uploads")
], JbLazyLoadingUploads);

export {
  JbLazyLoadingUploads
};
